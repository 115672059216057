import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

class Complete extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Payment Complete!" />
        <h1>Payment Complete</h1>
        <p>Thank you so much for your purchase!</p>
      </Layout>
    );
  }
}

export default Complete;
